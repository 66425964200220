import React, { useState, useEffect } from 'react'
import { ReactComponent as Logo } from './logo.svg'
import { ArrowDownCircleIcon } from 'lucide-react'
import { Popup } from './Popup'

import './styles.css'

export default function Overlay({ setOpen, open }) {
  const [fadeIn, setFadeIn] = useState(false)
  const [hover, setHover] = React.useState(false)
  const [dialog, setDialog] = useState(false)

  useEffect(() => {
    setFadeIn(true)
  }, [])

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%', // Ensure the container takes up the full width
    padding: '0px',
    marginBottom: '10px'
  }

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center', // Center content vertically inside the button
    borderRadius: '5px',
    backgroundColor: open ? 'white' : hover ? 'white' : 'black',
    color: hover ? 'black' : '#ccc',
    border: '1px solid ' + open ? '#ccc' : '#ccc',
    padding: open ? '0px' : '10px 20px',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0, // Add this to allow centering
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Spread items across the full width
        margin: 'auto',
        padding: 40,
        marginTop: 10
      }}
      className={`fade-in ${fadeIn ? 'visible' : ''}`}>
      <div style={containerStyle}>{!open && <Logo height={35} width={35} alt="Klicka logo" style={{ borderRadius: '2px' }} />}</div>
      {open && (
        <button onClick={() => setOpen(false)} style={buttonStyle} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <div style={{ marginTop: '4px', minWidth: open ? 10 : 90, marginLeft: '3px', display: 'inline' }}>
            <ArrowDownCircleIcon style={{ color: 'black' }} height={26} />
          </div>
        </button>
      )}
      {!open && (
        <div>
          <button style={buttonStyle} onClick={() => setDialog(true)}>
            CONTACT
          </button>
          {dialog ? <Popup text="Hello there!" closePopup={() => setDialog(false)} /> : null}
        </div>
      )}
    </div>
  )
}
