import * as THREE from 'three'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Environment, useGLTF } from '@react-three/drei'
import { useSpring } from '@react-spring/core'
import { a as three } from '@react-spring/three'
import { a as web } from '@react-spring/web'
import Overlay from './Overlay'
import { Text } from '@react-three/drei'
import Footer from './Footer'

function Model({ open, hovered, scale, hinge, ...props }) {
  const group = useRef()

  const [video] = useState(() =>
    Object.assign(document.createElement('video'), {
      src: '/aiv.mp4',
      crossOrigin: 'Anonymous',
      playsInline: true,
      loop: true,
      muted: true
    })
  )

  const texture = new THREE.VideoTexture(video)
  texture.encoding = THREE.sRGBEncoding
  // Flip the video vertically for the inverted monitor
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping
  texture.repeat.set(1, -1)
  texture.offset.set(0, 1)

  useEffect(() => void video.play(), [video])

  // Load the display screen model
  const { nodes, materials } = useGLTF('/mac-draco.glb')
  // Take care of cursor state on hover
  // Make it float in the air when it's opened
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, open ? Math.cos(t / 10) / 10 + 0.25 : 0, 0.9)
    group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, open ? Math.sin(t / 10) / 4 : 0, 0.9)
    group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, open ? Math.sin(t / 10) / 10 : 0, 0.1)
    group.current.position.y = THREE.MathUtils.lerp(group.current.position.y, open ? (-2 + Math.sin(t)) / 3 : -4.3, 0.1)
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <three.group rotation-x={hinge} scale={open ? (scale ? 1.3 : 2.3) : 1.1} position={[0, open ? (scale ? 5 : 1.5) : scale ? 4 : 3, 0]}>
        <group position={[0, 1.96, -0.13]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            material={
              hovered
                ? new THREE.MeshBasicMaterial({ color: 0xcccccc, transparent: true, opacity: 0.8 })
                : new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.6 })
            }
            geometry={nodes['Cube008'].geometry}
          />
          <mesh material={materials['matte.001']} geometry={nodes['Cube008_1'].geometry} />
          <mesh geometry={nodes['Cube008_2'].geometry} position={[0, 0, 0]} rotation={[0, 0, 0]}>
            <meshBasicMaterial toneMapped={false}>
              <primitive object={texture} attach="map" />
            </meshBasicMaterial>
          </mesh>
          // TODO, MAKE INTO LOW SHORT GIF?
          <mesh geometry={nodes['Cube008_2'].geometry} position={[0, 0.03, -0.2]} rotation={[Math.PI, 0, 0]}>
            <meshBasicMaterial toneMapped={false}>
              <primitive object={texture} attach="map" />
            </meshBasicMaterial>
          </mesh>
          {!open && (
            <Text
              font="/Roboto/Roboto-Thin.ttf"
              position={[0, -2.2, -1.5]}
              letterSpacing={0.03}
              rotation={[Math.PI / 2, 0, 0]}
              fontSize={hovered ? 0.9 : 0.8}
              color={hovered ? 'black' : '#ccc'}>
              READ MORE
            </Text>
          )}
        </group>
      </three.group>
    </group>
  )
}

export default function App() {
  // This flag controls open state, alternates between true & false
  const [open, setOpen] = useState(false)
  // We turn this into a spring animation that interpolates between 0 and 1
  const props = useSpring({ open: Number(open) })

  const [hovered, setHovered] = useState(false)
  useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])

  const [scale, setScale] = useState(window.innerWidth < 800 ? true : false)
  //@TODO add more dimensions
  useEffect(() => {
    const handleResize = () => {
      setScale(window.innerWidth < 800 ? true : false)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <web.main style={{ background: props.open.to([0, 1], ['#000', '#fff']) }}>
        <web.h1
          style={{
            opacity: props.open.to([0, 1], [1, 0]),
            transform: props.open.to((o) => `translate3d(-50%,${o * 50 - 100}px,0)`),
            fontSize: scale ? '26px' : '64px'
          }}>
          We build AI models to automate <br /> human work on computers
        </web.h1>
        <web.div
          style={{
            position: 'absolute',
            bottom: '15%',
            left: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '900px',
            width: '100%', // Ensure the div takes full width up to the maxWidth
            padding: '20px',
            textAlign: 'center',
            color: '#000',
            opacity: props.open.to([0, 1], [0, 1]), // Apply opacity from props
            boxSizing: 'border-box', // Ensure padding is included in width calculation
            fontWeight: 400,
            fontSize: '2em',
            letterSpacing: '-0.045em'
          }}>
          <web.h2
            style={{
              margin: '0',
              padding: '0',
              opacity: props.open.to([0, 1], [0, 1]),
              transform: props.open.to((o) => `translate3d(0,${o * 50 - 70}px,0)`),
              fontSize: scale ? '32px' : '42px',
              color: '#000',
              whiteSpace: 'normal' // Allow text to wrap
            }}>
            Humans spend on average <u style={{ color: 'red' }}>3 hours per workday</u> doing repetitive tasks on computers
          </web.h2>
          <p
            style={{
              margin: '0px 0 0',
              padding: '0',
              fontSize: '0.6em',
              opacity: 1.0,
              color: '#3d3d3d',
              opacity: props.open.to([0, 1], [0, 1]),
              fontWeight: 'lighter',
              whiteSpace: 'normal', // Allow text to wrap
              letterSpacing: 1
            }}>
            We solve the most difficult aspect of computer automation by building open-source AI models that can observe & operate interfaces just like humans.
          </p>
        </web.div>
        <Canvas dpr={[1, 2]} camera={{ position: [0, 0, -45], fov: 35 }}>
          <three.pointLight position={[10, 10, 10]} intensity={2.5} color={props.open.to([0, 1], ['#f0f0f0', '#ccc'])} />
          <Suspense fallback={null}>
            <group
              rotation={[0, Math.PI, 0]}
              onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
              onPointerOut={(e) => {
                setHovered(false)
              }}
              onClick={(e) => (e.stopPropagation(), setOpen(!open))}>
              <Model open={open} scale={scale} hovered={hovered} hinge={props.open.to([0, 1], [2.5, -0.325])} />
            </group>
            <Environment preset="city" />
          </Suspense>
        </Canvas>
      </web.main>
      <Overlay open={open} setOpen={() => setOpen(!open)} />
      <Footer open={open} scale={scale} />
    </>
  )
}
