import React, { useState, useEffect } from 'react'

import './Popup.css'
export const Popup = ({ text, closePopup }) => {
  const [hover, setHover] = React.useState(false)

  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center', // Center the content horizontally
    alignItems: 'center', // Center the content vertically
    textAlign: 'center', // This can remain if you add more text or nested elements
    width: '100px',
    marginTop: 10,
    borderRadius: '5px',
    backgroundColor: hover ? 'white' : 'black',
    color: hover ? 'black' : '#ccc',
    border: '1px solid ' + open ? '#ccc' : '#ccc',
    padding: '10px 20px',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
  }

  const divStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center the content horizontally
    alignItems: 'center', // Center the content vertically
    textAlign: 'center', // This can remain if you add more text or nested elements
    marginTop: 10,
    color: 'black',
    marginTop: 10,
    width: '100%'
  }
  return (
    <div className="popup-container">
      <div className="popup-body">
        <div style={{ color: 'black', fontSize: 22, fontWeight: 'bold', marginTop: 10 }}>CONTACT US</div>

        <div style={divStyle}>
          <div style={{ fontWeight: 'bold' }}>Alexander Anserud, CEO</div>
          <div style={{}}>
            <a href="mailto:alex@klicka.ai">alex@klicka.ai</a>
          </div>
        </div>

        <div style={divStyle}>
          <div style={{ fontWeight: 'bold' }}> Tobias Norlund, CTO</div>
          <div style={{}}>
            <a href="mailto:tobias@klicka.ai">tobias@klicka.ai</a>
          </div>
        </div>

        <button style={buttonStyle} onClick={closePopup}>
          Close
        </button>
      </div>
    </div>
  )
}
