import React from 'react'

const Footer = ({ open, scale }) => {
  const footerStyle = {
    width: '100%',
    alignItems: 'center',
    padding: '40px 40px',
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: 'transparent'
  }

  const rightSectionStyle = {
    textAlign: 'center',
    color: open ? 'black' : '#ccc'
  }

  return (
    <div style={footerStyle}>
      <div style={rightSectionStyle}>
        <b style={{ fontSize: scale ? 10 : 14 }}>Researching frontier action models in Stockholm, Sweden 🇸🇪</b> <br></br>
        <div style={{ marginTop: 6, textTransform: 'uppercase', fontWeight: 'lighter', fontSize: scale ? 10 : 14 }}>
          &copy; {new Date().getFullYear()} Klicka Artificial Intelligence AB
        </div>
      </div>
    </div>
  )
}

export default Footer
